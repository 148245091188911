import React, { useEffect } from "react";
import NavMenu from "../components/_main/NavMenu";
import "../assets/styles/privacy.css";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Privacy1 = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    });
    return (
        <>
            <NavMenu />
            <div className="main-container bg-white">
                <div className="container-fluid bg-theme-color">
                    <div className="container section-title py-5">
                        <p className="text-white">Privacy Policy</p>
                    </div>
                </div>
                <p><br /></p>
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">INTRODUCTION</h4>
                    <p>Welcome to IndiansAbroad, a service provided by EXPONATE ONLINE SERVICES PVT LTD. (&quot;us,&quot; &quot;we,&quot; or &quot;our&quot;). This Privacy Policy is designed to inform you about how we collect, use, disclose, and protect your personal information when you use our website.</p>
                    <p>At &lsquo;IndiansAoad&rsquo;, a subsidiary of EXPONATE ONLINE SERVICES PVT LTD, we prioritize the security and privacy of your personal information. Your trust is of utmost importance to us, and we are committed to upholding the highest standards in safeguarding your privacy. You can trust &lsquo;IndiansAbroad&rsquo; to handle your personal information with great care. To understand how we collect and manage your data, please review this privacy statement. To earn and maintain your trust, we work diligently. &lsquo;IndiansAbroad&rsquo; utilizes your personal data exclusively to provide the services offered by our platform. Rest assured that your personal information will never be rented, sold, or shared with third parties for advertising purposes.</p>
                    <p>For any queries you can contact us:</p>
                    <p>EXPONATE ONLINE SERVICES PVT LTD&nbsp;</p>
                    <p>Email: <a data-fr-linked="true" href="mailto:support@indiansabroad.online">support@indiansabroad.online</a></p>

                    <p><br /></p>
                </div>
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">INFORMATION WE COLLECT</h4>
                    <h5 className="font18 extraBold">Personal Information</h5>
                    <p>We will exclusively gather your personal information when essential to furnish a requested service, such as when you reach out to us, create an account on our website, subscribe to services, or access content on the Platform(s). In this document, we elaborate on the specifics of our information collection process for your convenience under the section titled &quot;Personal Information Provided by you. &nbsp;The type of personal information we gather is contingent upon the nature of your interactions with us and the Services, the decisions you make, and the products and features you utilize. Such information may encompass the following:</p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">Names</li>
                            <li className="font18">Phone numbers</li>
                            <li className="font18">Email addresses</li>
                            <li className="font18">Mailing addresses</li>
                            <li className="font18">Job titles</li>
                            <li className="font18">Usernames</li>
                            <li className="font18">Passwords</li>
                            <li className="font18">Contact preferences</li>
                            <li className="font18">Contact or authentication data</li>
                            <li className="font18">Debit/credit card numbers</li>
                            <li className="font18">City</li>
                            <li className="font18">Country in which user is living now</li>
                            <li className="font18">University name</li>
                            <li className="font18">Photo</li>
                            <li className="font18">Payment data</li>
                        </ul>
                    </div>
                    <p>We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument.</p>
                    <h5 className="font18 extraBold">Non-Personal Information</h5>
                    <p>We collect information using cookies and other similar technologies to help distinguish you from other users of our Platforms, and streamline your online.&nbsp;</p>
                    <p>When you visit our Platforms, we may collect the following information:</p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">Your activity logs on the Platform(s) - Which pages you access, view and which links you follow;</li>
                            <li className="font18">Any passwords that you use on our Platforms or any other authentication token used to login;</li>
                            <li className="font18">URL and time stamps;</li>
                            <li className="font18">Details of your visits to our Platforms and the resources that you access, including, but not limited to, traffic data, location data, web logs and other communication data.</li>
                        </ul>
                    </div>
                    <p><br /></p>
                </div>
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">PURPOSE OF COLLECTION OF INFORMATION</h4>
                    <p>We collect information (including Personal Information) to provide the Services and for the purposes as outlined below including but not limited to:</p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">To help us identify you when you log into the Platform and when you register</li>
                            <li className="font18">An account with us, and, to map a specific profile with an authorized user;</li>
                            <li className="font18">To help facilitate in-app purchases and make subscribed and paid content available to you;</li>
                            <li className="font18">To enhance the quality of services that we provide and improve your experience during browsing;</li>
                            <li className="font18">To personalize your browsing experience on the Platform(s);</li>
                            <li className="font18">To recommend videos, content, news to users you might be interested in;</li>
                            <li className="font18">For providing location-based services;</li>
                            <li className="font18">For the performance of a contractual and legal obligation;</li>
                            <li className="font18">To communicate with you;</li>
                            <li className="font18">To provide you with news, special offers, general information, commercial communications about other products and services along with marketing information and surveys;</li>
                            <li className="font18">To provide and process service requests initiated by you.</li>
                            <li className="font18">Notification of any changes in the terms of use or privacy policy;</li>
                            <li className="font18">Resolution of any queries related to your use of Services.</li>
                            <li className="font18">Any other new services developed by us, our affiliates, subsidiaries and /or third party partners.</li>
                            <li className="font18">To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                            <li className="font18">To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</li>
                            <li className="font18">To respond to user inquiries/offer support to users. We may process our information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
                            <li className="font18">To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
                            <li className="font18">To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
                            <li className="font18">To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
                            <li className="font18">To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
                            <li className="font18">To save or protect an individual&apos;s vital interest. We may process your information when necessary to save or protect an individual&apos;s vital interest, such as to prevent harm.</li>
                        </ul>
                    </div>
                    <p><br /></p>
                </div>
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">HOW DO WE HANDLE YOUR INFORMATION.</h4>
                    <p className="font18">Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">Geo location Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device&apos;s settings.</li>
                            <li className="font18">Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device&apos;s camera, contacts, microphone, SMS messages, storage, and other features. If you wish to change our access or permissions, you may do so in your device&apos;s settings.</li>
                            <li className="font18">Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device&apos;s settings. This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</li>
                            <li className="font18">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</li>
                            <li className="font18">In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests. We may rely on the following legal bases to process your personal information:</li>
                            <li className="font18">Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose.&nbsp;</li>
                            <li className="font18">Performance of a Contract. We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</li>
                            <li className="font18">Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</li>
                            <li className="font18">Analyze how our Services are used so we can improve them to engage and retain users</li>
                            <li className="font18">Diagnose problems and/or prevent fraudulent activities</li>
                            <li className="font18">Understand how our users use our products and services so we can improve user experience</li>
                            <li className="font18">Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
                            <li className="font18">Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
                        </ul>
                    </div>
                    <p><br /></p>
                </div >
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">SHARING AND USE</h4>
                    <p>We may share your information with our trusted partners or third parties who provide us with support services, for meeting the obligations agreed to between you and us.</p>
                    <p>We may use and/or disclose your information with the Company-controlled businesses, affiliates, subsidiaries and other entities within the Times group of companies, other third party business partners, service providers including but not limited to services provider, advertising networks, technology partner, social networks and any other third parties:</p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">To assist them to reach out to you in relation to their programs or campaigns(including marketing and sales) and to process your query / requests (such as job application) and/or provide with the services;</li>
                            <li className="font18">To process payments on our behalf. Our payment provider will collect and process your payment details; we will not store or have access to your full credit or debit card details, except any transactional information for the purpose of reconciliation of transactions on our Platform(s).</li>
                            <li className="font18">To send e-mails, instant messages, social media messages and SMS messages and/or manage contact management systems.</li>
                            <li className="font18">recommendations services, ad tech services, analytic services, including but not limited to click stream information, browser type, time and date, information about your interactions with advertisements and other content, including through the use of cookies, beacons, mobile ad identifiers, and similar technologies, in order to provide content, advertising, or functionality or to measure and analyze ad performance, on our Services or Platforms and on 3rd party platforms.</li>
                            <li className="font18">Serve ads on our Platforms, affiliate websites, and any other digital platform available on the internet. However, the privacy policy of such digital platform shall be applicable upon the processing of information on such third party digital platform.</li>
                            <li className="font18">To assist and to reach out to you in relation to various programs or campaigns (including marketing and sales) and/or to process your query /requests.</li>
                        </ul>
                    </div>
                    <p>We may further be required to share your personal information in certain exceptional circumstances; this would be where we believe that the disclosure is:</p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">Required by the law, or in order to comply with judicial proceedings, court orders or legal or regulatory proceedings.</li>
                            <li className="font18">Necessary to protect the safety of our employees, our property or the public.</li>
                            <li className="font18">Necessary for the prevention or detection of crime, including exchanging information with other companies or organizations for the purposes of fraud protection and credit risk reduction.</li>
                            <li className="font18">Proportionate as part of a merger, business, or asset sale, in the event that this happens we will share your information with the prospective seller or buyer involved.</li>
                        </ul>
                    </div>
                    <p><br /></p>
                </div >
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h4>
                    <p>We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.</p>
                    <p>The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link to a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.</p>
                    <p><br /></p>
                </div >
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">DATA RETENTION</h4>
                    <p>&nbsp;We will only store your personal information for as long as we need it for the purposes for which it was collected. Retention periods for records will also be based on the type of record, the nature of the activity and product and service that the same is associated with, linked to</p>
                    <p>We retain your information till such period that is required for the purposes of us meeting your requests on our Platforms, and in compliance with the applicable laws, statutory requirements.</p>
                    <p><br /></p>
                </div>
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">INFORMATION SECURITY</h4>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">We take commercially reasonable technical, physical, and organizational steps to safeguard any information you provide to us, to protect it from unauthorized access, loss, misuse, or alteration.</li>
                            <li className="font18">We try to ensure that all information you provide to us is transferred securely via the website (always check for the padlock symbol in your browser, and &ldquo;https&rdquo; in the URL, to ensure that your connection is secure).</li>
                            <li className="font18">All information you provide to us is stored on secure servers. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our website, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</li>
                            <li className="font18">Although we take reasonable security precautions, no computer system or transmission of information can ever be completely secure or error-free, and you should not expect that your information will remain private under all circumstances. The Company will not be liable for any damages of any kind arising from the use of the Platform, including, but not limited to any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly or any loss of data, use, good-will, or other intangible losses</li>
                        </ul>
                    </div>
                    <p><br /></p>
                </div>
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">DATA SUBJECT RIGHTS</h4>
                    <p>If you require any further information about your rights as explained below, or if you would like to exercise any of your rights, please reach out to us by writing to the Grievance Officer of the Company.</p>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">You have the right to access your personal data.</li>
                            <li className="font18">You have the right to correct any inaccurate or incomplete personal data</li>
                            <li className="font18">You have the right to withdraw your consent</li>
                            <li className="font18">You have the right to request deletion of your account</li>
                            <li className="font18">However, we may maintain backup copies of your information, to the extent necessary to comply with our legal obligations.</li>
                        </ul>
                    </div>
                    <p><br /></p>
                </div >
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">CROSS-BORDER TRANSFERS</h4>
                    <p>We operate globally and may transfer your personal information to individual companies of the Times Internet Limited affiliated companies or third parties in locations around the world for the purposes described in this privacy policy, only upon satisfaction that the country has an adequate and appropriate level of protection, and the transfer of information is lawful. We shall comply with our legal and regulatory obligations in relation to your Information, including having a lawful basis for transferring Personal Information and putting appropriate safeguards in place to ensure an adequate level of protection for the Personal Information. We will also ensure that the recipient in such alternate country is obliged to protect your Information at a standard of protection comparable to the protection under applicable laws. Our lawful basis for such transfer will be either on the basis of content or one of the safeguards permissible by laws.</p>
                    <p><br /></p>
                </div >
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">CHILDREN PRIVACY</h4>
                    <div className="container">
                        <ul className="subTcBlock" style={{ listStyleType: "disc" }}>
                            <li className="font18">You must have attained the age of majority to be able to use and access our Services. If you are a minor in your jurisdiction, your registration and use of our Services must be with the supervision of an adult.</li>
                            <li className="font18">As a parent or legal guardian, please do not allow your minors under your care to submit Personal Information to us. In the event that such Personal Information of a minor is disclosed to us, you hereby consent to the processing of the minor&rsquo;s Personal Information and accept and agree to be bound by this Privacy Policy and take responsibility for his or her actions.</li>
                            <li className="font18">However, if the Company is notified that the personal data of individuals below the age of majority has been collected by us, without user or parental consent, we shall take all necessary steps to delete such information from our servers. As a parent or legal guardian, you are encouraged to reach out to the Grievance Officer, for redressal of any complaints in relation to collection of children data.</li>
                        </ul>
                    </div>
                    <p><br /></p>
                </div >
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
                    <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&apos;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
                    <p><br /></p>
                </div >
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">CHANGES TO THE PRIVACY POLICY</h4>
                    <p>Any changes we make to our privacy policy in the future, to incorporate necessary changes in technology, applicable law, will be posted on this page and, where appropriate, notified to you by e-mail. Your use of the Services after such notice shall be deemed as acceptance of such changes to our Privacy Policy. Please check back frequently to see any updates or changes to our privacy policy.</p>
                    <p>This version was last updated on Jan 31, 2024 and historic versions can be obtained by contacting us.</p>
                    <p><br /></p>
                </div >
                <div className="container tcBlock">
                    <h4 className="font18 extraBold">GRIEVANCE REDRESSAL</h4>
                    <p>If you have any complaints, concerns with regards to the use, storage, deletion, and disclosure of your personal information provided to us, you may reach out to us on the provided email below. <a data-fr-linked="true" href="mailto:support@indiansabroad.online">support@indiansabroad.online</a>.</p>
                    <p><br /></p>
                    <p><br /></p>
                </div >
            </div >
            <Footer />
        </>
    );
};

export default Privacy1;